<template>
  <print-table v-if="form.id" :form="form" :historyRecord="historyRecord"
               title="转正申请">
    <template v-slot:form>
      <div class="info_item">
        <div>员工编号</div>
        <div>{{form.jobNumber}}</div>
      </div>
      <div class="info_item">
        <div>姓名</div>
        <div>{{form.staffName}}</div>
      </div>
      <div class="info_item">
        <div>聘用部门</div>
        <div>{{form.sysDeptName.deptName||''}}</div>
      </div>
      <div class="info_item">
        <div>聘用岗位</div>
        <div>{{form.sysPositionNameList&&form.sysPositionNameList.length?form.sysPositionNameList.map((e) => e.positionName).join(","):''}}</div>
      </div>
      <div class="info_item">
        <div>试用开始日期</div>
        <div>{{form.beginTrailTime}}</div>
      </div>
      <div class="info_item">
        <div>试用截止日期</div>
        <div>{{form.endTrailTime}}</div>
      </div>
      <div class="info_item">
        <div>迟到次数</div>
        <div>{{form.lateCount}}</div>
      </div>
      <div class="info_item">
        <div>早退次数</div>
        <div>{{form.earlyCount}}</div>
      </div>
      <div class="info_item">
        <div>矿工天数</div>
        <div>{{form.absenteeismDay}}</div>
      </div>
      <div class="info_item">
        <div>事假天数</div>
        <div>{{form.personalLeaveDay}}</div>
      </div>
      <div class="info_item">
        <div>病假天数</div>
        <div>{{form.sickLeaveDay}}</div>
      </div>
      <div class="info_item">
        <div>试用期目标考核得分</div>
        <div>{{form.targetAssessmentScore}}</div>
      </div>
      <div class="info_item">
        <div>综合素质评估得分</div>
        <div>{{form.comprehensiveQualityScore}}</div>
      </div>
      <div class="info_item">
        <div>转正述职答辩得分</div>
        <div>{{form.lectureReportScore}}</div>
      </div>
      <div class="info_item">
        <div>本次合同主体</div>
        <div>{{form.contractsPartA}}</div>
      </div>
      <div class="info_item">
        <div>审批类型</div>
        <div>{{form.activityKeyName||''}}</div>
      </div>
    </template>
  </print-table>
</template>

<script>
import PrintTable from "@/components/print-table";

export default {
  name: "positive-print",
  components: {PrintTable},
  data(){
    return {
      id:0,
      form:{},
      historyRecord:[],
    }
  },
  created(){
    this.id=this.$route.query.id;
    this.getDetail();
  },
  methods:{
    getDetail() {
      this.$api.personnel.getPositiveDataDetail({id:this.id}).then((res) => {
        this.form = res.data;
        if (res.data.hasHistory) {
          this.getHistoryActivity(res.data.id);
        }
      })
    },
    getHistoryActivity(id) {
      this.$api.personnel.getPositiveHisFlow({id}).then(response => {
        if (response.code === 200) {
          this.historyRecord = response.data.list;
        }
      })
    }
  }

}
</script>

<style scoped lang="scss">


</style>